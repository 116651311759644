import { useHasPermission } from 'admin-portal-shared-services';
import { useDecision } from '@optimizely/react-sdk';
import {
  OPTIMIZELY_FRONTLINE_SIDEBAR,
  OPTIMIZELY_KEY_CARD_OPERATIONS_PANEL,
} from '../../../consts/optimizely';
import { Scopes } from '../../../models/scopes';

export function useCheckPermissions() {
  const [operationsPanel, operationsPanelReady] = useDecision(OPTIMIZELY_KEY_CARD_OPERATIONS_PANEL);
  const [frontlineSidebar, frontlineSidebarReady] = useDecision(OPTIMIZELY_FRONTLINE_SIDEBAR);

  const hasOperationsReadTaskPermission = useHasPermission([Scopes.BeesForceOperationsGlobalRead]);

  const isOperationsPanelVisible = operationsPanel.enabled && operationsPanelReady;

  const hasVOneCardPermission = useHasPermission([Scopes.BeesForceVOneCardRead]);
  const hasVTwoCardPermission = useHasPermission([Scopes.BeesForceVTwoCardRead]);
  const hasTaskGroupsPermission = useHasPermission(
    [Scopes.BeesForceTaskGroupsRead, Scopes.BeesForceTaskGroupsWrite],
    'OR'
  );
  const hasInviteUserPermission = useHasPermission([Scopes.BeesForceAdminInviteUser]);
  const hasTaskManagementPermission = useHasPermission([Scopes.BeesForceTaskManagementWrite]);
  const isFrontlineSidebarVisible = frontlineSidebar.enabled && frontlineSidebarReady;

  return {
    hasOperationsReadTaskPermission,
    isOperationsPanelVisible,
    hasVOneCardPermission,
    hasVTwoCardPermission,
    hasTaskGroupsPermission,
    hasInviteUserPermission,
    hasTaskManagementPermission,
    isFrontlineSidebarVisible,
  };
}
