import { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PageLoading from './pages/PageLoading';
import { Sidebar } from './components/Sidebar/Sidebar';

const Switch = lazy(() => import('./pages/Switch'));

const Router = (): JSX.Element => {
  return (
    <BrowserRouter>
      <Suspense fallback={<PageLoading />}>
        <Routes>
          <Route path="/force" element={<Switch />} />
          <Route path="*" element={<Sidebar />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default Router;
